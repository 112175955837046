import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';
import QuickLink from '../../components/QuickLink';
import QuickLinks from '../../components/QuickLinks';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const QuicklinksModule = ({ title, items, ...p }) => {
  return (
    <Container {...p} flex col gap="20px">
      {!!title && <Heading color="bahamaBlue" title={title} />}
      <QuickLinks>
        {items?.map((i, ix) => (
          <QuickLink key={ix} index={ix + 1} {...i} />
        ))}
      </QuickLinks>
    </Container>
  );
};

export default QuicklinksModule;

registerModule({ Quicklinks: Self });
