import styled from '@emotion/styled';
import svgToDataUrl from '../helpers/svg-to-data-url';
import { transparentize, lighten, mix } from 'polished';

const chevronRight = `<svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.67202 5L0.843018 2.172L2.25702 0.757004L6.50002 5L2.25702 9.243L0.843018 7.828L3.67202 5Z" fill="#1E4275"/>
</svg>
`;

export default styled.a`
  ${({ theme }) => theme.fonts.openSans.semibold};

  --background-color: ${({ theme }) => theme.colors.aliceBlue};
  --circle-border-color: ${({ theme }) => theme.colors.quartz};
  --border-color: ${({ theme }) => theme.colors.quartz};
  --text-color: ${({ theme }) => theme.colors.bahamaBlue};
  --circle-text-color: ${({ theme }) => theme.colors.bahamaBlue};
  --counter-outside: 25px;

  font-size: 14px;
  line-height: 120%;
  position: relative;
  display: flex;
  height: 72px;
  color: var(--text-color);
  margin-left: var(--counter-outside);

  border: solid thin var(--border-color);
  background-color: var(--background-color);
  transition: color 150ms linear, background-color 150ms linear;
  border-radius: 4px;

  .counter {
    align-self: center;
    margin-left: calc(0px - var(--counter-outside));
  }
  .text {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .content {
    flex: 1;
    border-left: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    min-height: 50px;
    padding: 5px 10px;
  }
  .number {
    ${({ theme }) => theme.typography.heading3};
    fill: var(--circle-text-color);
    transition: fill 150ms linear;
  }
  .partial-rect {
    fill: var(--background-color);
    stroke: var(--border-color);
    transition: fill 150ms linear;
  }
  .number-circle {
    fill: ${({ theme }) => theme.colors.white};
    stroke: var(--circle-border-color);
    transition: stroke 150ms linear;
  }
  &[href] {
    .content {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) =>
        transparentize(0.5, theme.colors.bahamaBlue)};

      .text:after {
        content: url(${svgToDataUrl(chevronRight, true)});
        transition: transform 150ms ease-in-out;
        margin-left: 8px;
      }
    }
    ${({ theme }) => theme.media.hover} {
      &:hover {
        --circle-border-color: ${({ theme }) =>
          mix(0.3, theme.colors.bahamaBlue, theme.colors.quartz)};
        --text-color: ${({ theme }) => theme.colors.pacificBlue};
        .content:after {
          transform: translateX(5px);
        }
      }
    }

    &:active {
      --background-color: ${({ theme }) =>
        lighten(0.02, theme.colors.aliceBlue)};
      --circle-border-color: ${({ theme }) =>
        mix(0.2, theme.colors.bahamaBlue, theme.colors.quartz)};
      .content {
        &:after {
          transform: translateX(10px);
        }
      }
    }
  }
  .next-item {
    position: absolute;
    bottom: 0;
    left: calc(5px - var(--counter-outside));
    transform: translateY(19px);
  }
  &:last-child .next-item {
    display: none;
  }

  ${({ theme }) => theme.media.desktopSmall} {
    flex: 1;
    flex-direction: column;
    height: 122px;
    margin-left: 0;
    margin-top: var(--counter-outside);
    .counter {
      margin-left: 0;
      margin-top: calc(0px - var(--counter-outside));
      transform: rotate(90deg);
      .number {
        transform: rotate(-90deg);
        transform-origin: 23.5px 36px;
      }
    }
    .next-item {
      bottom: auto;
      top: calc(5px - var(--counter-outside));
      left: auto;
      right: 0;
      transform: translate(10px, -10px) rotate(-90deg) scaleX(-1);
    }

    .content {
      flex-direction: column;
    }
  }
`;
