import { useTheme } from '@emotion/react';
import React from 'react';
import Picture from './global/Picture';
import StyledQuickLink from './QuickLink.style';

const QuickLink = ({
  icon: { src: iconSrc },
  link: { href: linkHref, content: linkContent },
  index = 0,
}) => {
  const theme = useTheme();
  return (
    <StyledQuickLink 
    as={linkHref  ? 'a' : 'div'}
    {...(linkHref ? { href: linkHref } : {})}>
      <svg
        className="counter"
        width="62"
        height="62"
        viewBox="0 5 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className="partial-rect"
          x="24.5"
          y="0.5"
          width="320"
          height="71"
          rx="3.5"
        />
        <circle cx="24" cy="36" r="28" fill="white" />
        <path
          d="M24 64C39.464 64 52 51.464 52 36C52 20.536 39.464 8 24 8"
          stroke={theme.colors.quartz}
        />
        <circle className="number-circle" cx="24" cy="36" r="23.5" />
        <text
          className="number"
          x="24"
          y="37"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {index}
        </text>
      </svg>
      <svg
        className="next-item"
        width="8"
        height="29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.926 28.494a.5.5 0 0 0 .569-.42l.662-4.451a.5.5 0 0 0-.989-.148l-.484 3.253-.752.607-3.31-.492a.5.5 0 1 0-.147.989Z"
          fill={theme.colors.quartz}
        />
        <path
          d="M5.763 27.823C-.767 20.4-.81 8.928 6.05 1.063"
          stroke={theme.colors.quartz}
          strokeDasharray="3.4,1.6"
          strokeDashoffset="4.2"
        />
      </svg>

      <div className="content">
        <Picture
          className="icon"
          images={{
            1: iconSrc,
          }}
          width={40}
          height={40}
          alt=""
        />
        <div className="text">{linkContent}</div>
      </div>
    </StyledQuickLink>
  );
};
export default QuickLink;
