import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  align-self: center;
  ${({ theme }) => theme.media.desktopSmall} {
    flex-direction: row;
    max-width: unset;
  }
`;
